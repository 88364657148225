import React from 'react';
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo"

export default ({location}) => (
  <Layout location={location}>
    <Seo title="El Último Mono"/>
    <div className="pv2 flex flex-column items-center sans-serif mb2">
      <h1 className="db f3">Biografía</h1>
      <div className="ph4 lh-copy  mw8 center">
        <p>Cuentan las historias que, para cruzar al otro lado de un río, los monos se colocaban formando una cadena en lo alto de un árbol, ayudándose los unos a los otros.</p>
        <p>El que había ayudado a los demás a balancearse para cruzar el río, difícilmente tendría el impulso suficiente para alcanzar el otro lado de la orilla.</p>
        <p>El Último Mono habla de aprender a nadar, de saltar y sumergirse en aguas de incertidumbre a sabiendas de que puede que no llegues al otro lado. Habla del vértigo, que no es otra cosa que las ganas de dejarse caer.</p>
      </div>
      <h2 className="db f3">Quiénes Somos</h2>
      <div className="ph4 lh-copy  mw8 center">
        <p className="mv0">Christian Bernaus | Batería</p>
        <p className="mv0">David Hernández | Bajo y coros</p>
        <p className="mv0">Jorge Morales | Guitarra solista y coros</p>
        <p className="mv0">Sergio Ruiz | Voz y Guitarra rítmica</p>
      </div>
    </div>

  </Layout>
)
